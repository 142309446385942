import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { Router, provideRouter } from "@angular/router";
import { setUpLocationSync } from "@angular/router/upgrade";
import { UpgradeModule, downgradeComponent, downgradeInjectable } from "@angular/upgrade/static";
import { FooterComponent, HeaderComponent, SidebarComponent, SidebarService } from "@kno2/common/layout";
import { HttpUtilitiesService } from "@kno2/common/services";
import { LoadingIndicatorService, RouteRegistryService, apiInterceptor, authInterceptor, loadingInterceptor } from "@kno2/core";
import { EulasService } from "@kno2/data-access/eulas";
import { SupportService } from "@kno2/data-access/support";
import { AdminOrganizationsQhinComponent } from "@kno2/features/admin/organizations";
import { PatientAddModalComponent } from "@kno2/features/intake/message";
import { ContactsComponent } from "@kno2/features/settings/contacts";
import { AccountEditProfileServiceToken, AppConfigToken, CommonDataToken } from "@kno2/interop";
import { AdminModule } from "@kno2/ng1/admin/admin.module";
import { AdminDocumentSourcesService } from "@kno2/ng1/admin/documentsources/admin-documentsources.service";
import { AppConfig } from "@kno2/ng1/app-config";
import { DIRECTORY_API_BASE_URL, SocketService } from "@kno2/shared/services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as angular from "angular";
import { routes } from "./app.routes";

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes),
        provideHttpClient(withInterceptors([loadingInterceptor, apiInterceptor, authInterceptor])),
        importProvidersFrom([BrowserModule, UpgradeModule]),
        {
            provide: AppConfigToken,
            useFactory: (upgrade: UpgradeModule) => upgrade.$injector.get("appConfig"),
            deps: [UpgradeModule]
        },
        {
            provide: CommonDataToken,
            useFactory: (upgrade: UpgradeModule) => upgrade.$injector.get("CommonData"),
            deps: [UpgradeModule]
        },
        {
            provide: AccountEditProfileServiceToken,
            useFactory: (upgrade: UpgradeModule) => upgrade.$injector.get("AccountEditProfileService"),
            deps: [UpgradeModule]
        },
        {
            provide: DIRECTORY_API_BASE_URL,
            useFactory: (appConfig: AppConfig) => appConfig.directoryApiUrl,
            deps: [AppConfigToken]
        },
        {
            provide: AdminDocumentSourcesService,
            useFactory: (upgrade: UpgradeModule) => upgrade.$injector.get("AdminDocumentSourcesService"),
            deps: [UpgradeModule]
        },
        SocketService,
        {
            provide: APP_INITIALIZER,
            useFactory: (upgrade: UpgradeModule) => async () => {
                const injector = angular.injector(["ng", "app.config"]);
                const appConfigService: any = injector.get("AppConfigService");
                const appConfig = await appConfigService.load();

                angular.module("app.config").constant("appConfig", appConfig);

                const kno2ClientEl = document.getElementById("kno2-client");
                const kno2AdminEl = document.getElementById("kno2-admin");

                if (kno2ClientEl) {
                    getDefaultModules(angular.module("kno2.client"))
                        .service("HttpUtilitiesService", downgradeInjectable(HttpUtilitiesService))
                        .service("SupportService", downgradeInjectable(SupportService))
                        .directive("kno2Contacts", downgradeComponent({ component: ContactsComponent }))
                        .directive("kno2PatientAddModal", downgradeComponent({ component: PatientAddModalComponent }));
                } else if (kno2AdminEl) {
                    getDefaultModules(angular.module(AdminModule.name)).directive(
                        "kno2AdminOrganizationsQhin",
                        downgradeComponent({ component: AdminOrganizationsQhinComponent })
                    );
                }

                function getDefaultModules(module) {
                    return module
                        .service("loadingIndicatorService", downgradeInjectable(LoadingIndicatorService))
                        .service("EulasService", downgradeInjectable(EulasService))
                        .service("ngxRouter", downgradeInjectable(Router))
                        .service("ngbModalService", downgradeInjectable(NgbModal))
                        .service("routeRegistryService", downgradeInjectable(RouteRegistryService))
                        .service("sidebarService", downgradeInjectable(SidebarService))
                        .directive("kno2Header", downgradeComponent({ component: HeaderComponent }))
                        .directive("kno2Sidebar", downgradeComponent({ component: SidebarComponent }))
                        .directive("kno2Footer", downgradeComponent({ component: FooterComponent }));
                }

                return new Promise((resolve) => {
                    if (kno2ClientEl) upgrade.bootstrap(kno2ClientEl, ["kno2.client"]);
                    if (kno2AdminEl) upgrade.bootstrap(kno2AdminEl, [AdminModule.name]);
                    setUpLocationSync(upgrade);
                    resolve(null);
                });
            },
            deps: [UpgradeModule],
            multi: true
        }
    ]
};
